<template>
  <div class="inspect-not-content">
    <van-nav-bar
      :border="false"
      left-arrow
      title="检查内容"
      @click-left="$router.back()"
    />
    <div class="content">
      <van-empty :image="emptyImage" class="empty-content">
        <div slot="description">
          <p class="tips-1">点击保存即可完成打卡</p>
          <p class="tips-2">当前巡检点无需填写检查项</p>
          <p class="tips-3">检查完成后请移至下一个巡检点继续执行巡检任务。</p>
        </div>
      </van-empty>
      <van-button class="btn" round type="info" @click="onSave">
        保存
      </van-button>
    </div>
  </div>
</template>

<script>
import emptyImage from "@/assets/images/not-content.svg";
import { saveTaskPoint } from "@/api/psm/inspectionNew";

export default {
  name: "InspectNotContent",
  props: ["recordId", "pointId"],
  data() {
    return {
      emptyImage
    };
  },
  methods: {
    async onSave() {
      const { recordId, pointId } = this;
      await saveTaskPoint(null, null, { recordId, pointId });
      this.$router.back();
    }
  }
};
</script>

<style lang="scss">
.inspect-not-content {
  .empty-content {
    padding-top: 160px;
    box-sizing: border-box;

    p {
      color: #8c8f97;
      text-align: center;
    }

    .tips-1 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 20px;
      margin-top: 12px;
    }

    .tips-2,
    .tips-3 {
      font-size: 12px;
      margin: 10px 0;
    }

    .van-empty__description {
      padding: 0;
    }

    .van-empty__image {
      width: 84px;
      height: 68px;
    }
  }

  .content {
    height: calc(100vh - 58px);
    position: relative;

    .btn {
      position: absolute;
      bottom: 48px;
      left: 16px;
      width: calc(100% - 32px);
      margin: auto;
    }
  }
}
</style>
